"use client";

import Button from "@/components/ui/Button/Button";
import { TestimonialTooltip } from "@/components/ui/TestimonialTooltip/TestimonialTooltip";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import Image from "next/image";
import { Link } from "@/lib/i18n";
import { useEffect, useRef, useState } from "react";
import * as m from "@/paraglide/messages";
import { languageTag } from "@/paraglide/runtime";
import { useExtraProp } from "@/contexts/ExtraPropContext";

export default function HeroForMain(props) {
    const extraProp = useExtraProp();
    const locale = languageTag();

    const [translateX, setTranslateX] = useState("0%");
    const [translateX2, setTranslateX2] = useState("0%");
    const [translateY2, setTranslateY2] = useState("0%");
    const [translateX3, setTranslateX3] = useState("0%");
    const [translateY3, setTranslateY3] = useState("0%");
    const [translateX4, setTranslateX4] = useState("0%");

    useEffect(() => {
        if (!extraProp.current) return;

        const handleScroll = () => {
            const scrollValue = extraProp.current.scrollTop;

            // Calculate transforms based on scroll position
            const x1 = `${Math.max(-150, (scrollValue / 380) * -150)}%`;
            const x2 = `${Math.max(-100, (scrollValue / 380) * -100)}%`;
            const y2 = `${Math.min(150, (scrollValue / 380) * 150)}%`;
            const x3 = `${Math.min(100, (scrollValue / 380) * 100)}%`;
            const y3 = `${Math.min(150, (scrollValue / 380) * 150)}%`;
            const x4 = `${Math.min(100, (scrollValue / 380) * 100)}%`;

            setTranslateX(x1);
            setTranslateX2(x2);
            setTranslateY2(y2);
            setTranslateX3(x3);
            setTranslateY3(y3);
            setTranslateX4(x4);
        };

        extraProp.current.addEventListener("scroll", handleScroll);

        return () => {
            if (extraProp.current) {
                extraProp.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [extraProp.current]);

    return (
        <div className="relative z-0 md:h-[calc(100vh-50px)]">
            <div className="z-0">
                <motion.div
                    className="absolute w-[240px] aspect-[19/49] md:block hidden -z-10"
                    style={{
                        inset: "0% auto auto 0%",
                        x: translateX,
                    }}
                >
                    <Image
                        src="/images/site/assets/hero-main-top-left.png"
                        alt="Asset"
                        fill
                        unoptimized
                    />
                </motion.div>
                <motion.div
                    className="absolute w-[380px] aspect-[190/141] md:block hidden -z-10"
                    style={{
                        inset: "auto auto 0% 0%",
                        x: translateX2,
                        y: translateY2,
                    }}
                >
                    <Image
                        src="/images/site/assets/hero-main-bottom-left.png"
                        alt="Asset"
                        fill
                        unoptimized
                    />
                </motion.div>
                <motion.div
                    className="absolute w-[391px] aspect-[391/267] md:block hidden -z-10"
                    style={{
                        inset: "auto 0% 0% auto",
                        x: translateX3,
                        y: translateY3,
                    }}
                >
                    <Image
                        src="/images/site/assets/hero-main-bottom-right.png"
                        alt="Asset"
                        fill
                        unoptimized
                    />
                </motion.div>
                <motion.div
                    className="absolute w-[244px] aspect-[488/1133] md:block hidden -z-10"
                    style={{
                        inset: "0% 0% auto auto",
                        x: translateX4,
                    }}
                >
                    <Image
                        src="/images/site/assets/hero-main-top-right.png"
                        alt="Asset"
                        fill
                        unoptimized
                    />
                </motion.div>
            </div>
            <section className="container md:py-40 py-14 z-10">
                <div
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className="lg:w-[950px] mx-auto xl:background-not-blur md:background-blur-white xl:p-0 md:p-10 p-5 rounded-2xl">
                        <h1
                            className="xl:text-6xl md:text-5xl sm:text-4xl text-3xl font-bold text-center dark:text-white mb-5"
                            style={{ lineHeight: 1.45 }}
                        >
                            {locale === "fr" && (
                                <>
                                    Le <span>nouveau</span>{" "}
                                    <span className="gradient-text border-2 rounded-xl border-primary px-1.5 py-1 inline-block">
                                        Golden Standard
                                    </span>{" "}
                                    de la formation{" "}
                                    <span>pour les développeurs</span>
                                </>
                            )}

                            {locale === "en" && (
                                <>
                                    The <span>new</span>{" "}
                                    <span className="gradient-text border-2 rounded-xl border-primary px-1.5 py-1 inline-block">
                                        Golden Standard
                                    </span>{" "}
                                    of training <span>for developers</span>
                                </>
                            )}

                            {locale === "es" && (
                                <>
                                    El <span>nuevo</span>{" "}
                                    <span className="gradient-text border-2 rounded-xl border-primary px-1.5 py-1 inline-block">
                                        Golden Standard
                                    </span>{" "}
                                    de la formación{" "}
                                    <span>para desarrolladores</span>
                                </>
                            )}

                            {locale === "de" && (
                                <>
                                    Der <span>neue</span>{" "}
                                    <span className="gradient-text border-2 rounded-xl border-primary px-1.5 py-1 inline-block">
                                        Golden Standard
                                    </span>{" "}
                                    der Schulung <span>für Entwickler</span>
                                </>
                            )}

                            {locale === "it" && (
                                <>
                                    Il <span>nuovo</span>{" "}
                                    <span className="gradient-text border-2 rounded-xl border-primary px-1.5 py-1 inline-block">
                                        Golden Standard
                                    </span>{" "}
                                    della formazione{" "}
                                    <span>per gli sviluppatori</span>
                                </>
                            )}

                            {locale === "pt" && (
                                <>
                                    O <span>novo</span>{" "}
                                    <span className="gradient-text border-2 rounded-xl border-primary px-1.5 py-1 inline-block">
                                        Golden Standard
                                    </span>{" "}
                                    de treinamento{" "}
                                    <span>para desenvolvedores</span>
                                </>
                            )}

                            {locale === "ja" && (
                                <>
                                    開発者のためのトレーニングの
                                    <span>新しい</span>{" "}
                                    <span className="gradient-text border-2 rounded-xl border-primary px-1.5 py-1 inline-block">
                                        ゴールデンスタンダード
                                    </span>
                                </>
                            )}
                        </h1>
                        <p
                            className="text-black sm:text-xl text-lg text-center font-geistSans lg:pr-10"
                            style={{ lineHeight: "170%" }}
                        >
                            {m.HeroForMain_headline()}
                        </p>
                        <div className="mt-5 flex justify-center">
                            <TestimonialTooltip center />
                        </div>
                        <div className="mt-10 flex justify-center">
                            <Link href="/signup">
                                <Button type="pop">
                                    {m.HeroForMain_button()}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
